import { Link } from "gatsby";
import React from 'react';
import * as dateFormat from 'dateformat';

const workHistory = [
  {
    "title": "Clojure Engineer",
    "employer": "Gresham Technologies",
    "link": "https://www.greshamtech.com/",
    "from": "2023-03-01",
    "to": null,
    "descriptionHtml": (<>
      <p>At Gresham I've worked as a back end Clojure engineer, responsible for designing and building their Kafka Streams based application. This involved:</p>
      <ul>
        <li>Designing and building new Kafka Streams topologies</li>
        <li>Administering Kubernetes</li>
        <li>Maintaining existing topologies</li>
        <li>Working on a GraphQL API</li>
        <li>Performing data migrations</li>
        <li>A small amount of front end JavaScript and React work</li>
      </ul>
    </>)
  },
  {
    "title": "Contract Clojure Developer",
    "employer": "Signal AI",
    "link": "https://www.signal-ai.com/",
    "from": "2022-05-01",
    "to": "2022-12-01",
    "descriptionHtml": (<>
      <p>
        I worked on a variety of different microservices within Signal AI, mainly centred around data ingestion.
        This involved crafting REST interfaces, working with SQS queues, and building GraphQL services.
        It also required working with Terraform and Terragrunt to deploy infrastructure to AWS.
      </p>
    </>)
  },
  {
    "title": "Contract Backend Clojure Developer",
    "employer": "Hyde Housing",
    "link": "https://www.hyde-housing.co.uk/",
    "from": "2021-02-01",
    "to": "2022-03-01",
    "descriptionHtml": (<>
      <p>I worked with the <a target="_blank" href="https://redefine.io/">Redefine</a> consultancy on consolidating Hyde Housing's disparate data sources into Kafka. This involved:</p>
      <ul>
        <li>Designing and deploying AWS infrastructure using Terraform</li>
        <li>Building Kafka Streams applications in Clojure</li>
        <li>Interacting with external platforms, such as Salesforce and Workday</li>
        <li>Designing Hyde's domain model, and defining transformations from 3rd party data sources to this model</li>
        <li>I wrote an internal library, Airfix, for converting Avro schemas to <a href="https://github.com/metosin/malli" target="_blank">malli</a></li>
      </ul>
    </>)
  },
  {
    "title": "Contract Clojure/ClojureScript Developer",
    "employer": "All Street",
    "link": "https://sevva.ai/",
    "from": "2020-09-01",
    "to": "2021-02-09",
    "descriptionHtml": (<>
      <p>All Street is a fintech company building an AI-powered platform to aid investment research. Their platform uses AI to determine company ESG scores, find relevant financial documents, and detect recent news about companies.</p>
      <p>I worked on their main product, Sevva, across the whole Clojure/ClojureScript stack. This involved:</p>
      <ul>
        <li>Front end development and design</li>
        <li>Back end development, including interacting with MongoDB, Elastic and Kafka</li>
        <li>Data migrations</li>
        <li>Front end and back end architectural design</li>
      </ul>
    </>)
  },
  {
    "title": "Clojure Engineer",
    "employer": "Funding Circle",
    "link": "https://www.fundingcircle.com/uk/",
    "from": "2018-01-01",
    "to": "2020-08-01",
    "descriptionHtml": (<>
      <p>Funding Circle is a P2P loans company. I have been here since January 2018, working primarily on event-driven Clojure microservices leveraging Kafka. Architecture design is critical for this, and we heavily use DDD, event sourcing, and CQRS to guide us in this.</p>
      <p>I have worked on multiple projects, across both the borrower and investor sides of the business. Most recently, I have been working on FC's new automated platform, designed to make a decision on loan applications within 20 seconds.</p>
      <p>While at Funding Circle I have also worked on Jackdaw, our open-source Clojure library for interacting with Kafka. I also created a Clojure DSL for Kafka Streams called <a target="_blank" href="https://github.com/DaveWM/willa">Willa</a>, which I actively maintain.</p>
    </>)
  },
  {
    "title": "Senior Web Developer",
    "employer": "UVD",
    "link": "https://wemakewaves.digital/",
    "from": "2016-09-01",
    "to": "2017-12-01",
    "descriptionHtml": (<>
      <p>At UVD (now We Make Waves), I worked on both the front end and back end of a variety of web applications, for multiple clients.</p>
      <p>One of the larger projects I worked on was the <a target="_blank" href="https://www.limpidmarkets.co.uk/">Limpid Markets</a> trading platform. The front end used AngularJS, React and Redux. As well as developing new features and fixing bugs, I also provided direction on the overall architecture of the application. I also re-wrote the front end build system, and <a target="_blank" href="https://wemakewaves.medium.com/how-we-simplified-our-frontend-build-fe916220d6cf">wrote a blog post about it</a></p>
      <p>I was the lead developer on a project for an IOT street lights company. They were in the process of writing version 3 of their flagship product, and UVD was asked to develop the front end. I wrote the application in TypeScript and Angular 2 (with NgRx), making heavy use of RxJS and Ramda. I also <a target="_blank" href="https://wemakewaves.medium.com/how-we-approached-automated-testing-for-an-angular-4-app-952b60e58b20">wrote a blog post</a> about our testing strategy on this project.</p>
      <p>As part of a hackathon, I worked on a photo sharing app written in Elm and Elixir. There is a blog post about the hackathon <a target="_blank" href="https://web.archive.org/web/20220124015242/https://uvd.co.uk/blog/hack-week-things-elixir-phoenix-elm-mash/">here</a>.</p>
      <p>I organised a Clojure workshop for everyone at UVD in March 2017. This involved teaching everyone the basics of Clojure, and working through some exercises. You can read more about it <a target="_blank" href="https://web.archive.org/web/20220124003217/https://uvd.co.uk/blog/clojure-workshop/">here</a>.</p>
    </>)
  },
  {
    "title": "Front End Web Developer",
    "employer": "Potato London",
    "link": "https://p.ota.to/",
    "from": "2015-11-01",
    "to": "2016-09-01",
    "descriptionHtml": (<>
      <p>At Potato, I worked on web applications and marketing sites for clients such as Google and Nest. Most of these web apps were deployed on Google App Engine.</p>
      <p>I also worked on an internal web app which used React and Redux, with tests written in Mocha, Chai and Sinon.</p>
    </>)
  },
  {
    "title": "Software Developer",
    "employer": "Sporting Solutions",
    "link": "https://sportingsolutions.com/",
    "from": "2015-04-01",
    "to": "2015-11-01",
    "descriptionHtml": (<>
      <p>I worked on C# applications responsible for receiving sporting event feeds from external providers, and passing this feed on to other applications via a RabbitMQ message queue. Ingesting these event feeds involved using multiple different techniques, such as HTTP polling, listening to TCP sockets, and subscribing to IBM WebSphere message queues. I also worked on an internal C# MVC website for managing the feed data, an ASP.NET REST API, and a number of smaller C# applications.</p>
      <p>One major project that I was solely responsible for was re-writing our internal feed mangement website to an AngularJS SPA. I built this using ES6, Babel, Angular 1.4, Angular Material, LESS and Gulp.</p>
      <p>One other project I worked on was migrating all our logging from an unmaintained framework to Graylog. This involved large refactorings of multiple codebases, as well as running Graylog on our infrastructure.</p>
      <p>I also did a large amount of work on our automated tests and CI systems. I was put in charge of improving a suite of acceptance tests written in Specflow, and I also set up our TeamCity CI to run these tests.</p>
      <p>While I was at Sporting Solutions, I also created and maintained the <a target="_blank" href="https://www.reddit.com/r/csharp/comments/3j8ehp/objectdiffer_a_library_for_diffing_c_objects/">ObjectDiffer</a> and <a href="https://www.npmjs.com/package/graylog-api-helpers">GraylogApiHelpers</a> open source projects.</p>
    </>)
  },
  {
    "title": "Web Developer",
    "employer": "Blinkbox",
    "from": "2014-05-01",
    "to": "2015-04-01",
    "descriptionHtml": (<>
      <p>My role centred around creating web applications for internal use. These included applications for asset management, video file ingestion, and user management. The main application I worked on was an AngularJS SPA, with an ASP.NET Web API 2 backend. I worked with tools such as Grunt, Bower and NPM, as well as testing frameworks like Karma and Protractor.</p>
      <p>I also participated in a company hackathon as a team lead. My team built completely new search functionality in 2 days, and were awarded 2nd place.</p>
    </>)
  },
  {
    "title": "Full-Stack Web Developer",
    "employer": "Globecast",
    "from": "2020-04-29",
    "to": "2020-04-29",
    "descriptionHtml": (<>
      <p>At Globecast, I worked on a variety of projects, using technologies such as: MVC 5, Entity Framework, AngularJS, SignalR, Kendo UI, LESS and D3.</p>
      <p>I designed and built multiple web applications to perform tasks such as comparing programme schedules, and performing QC on video files.</p>
      <p>I also worked on an existing projects. One example is a system that ingested video files, processed them, and sent them to a 3rd party scheduling program. This system was composed of multiple C# console applications.</p>
      <p>My role also included some DevOps work, such as maintaining running applications, and performing video file migrations.</p>
    </>)

  },
  {
    "title": "C# Software Developer",
    "employer": "CPL Software",
    "link": "https://cplsoftware.com/",
    "from": "2011-12-01",
    "to": "2013-11-01",
    "descriptionHtml": (<>
      <p>My role at CPL Software was primarily developing a C# WinForms app for <a target="_blank" href="https://www.mygov.scot/property-factors/">property factors</a>. This application has a variety of functions, including: producing invoices, calculating taxes, sending out letters and emails, and importing bank transactions. My role also included:</p>
      <ul>
        <li>1st, 2nd and 3rd line support.</li>
        <li>Writing SQL stored procedures, performing data migrations and fixes, and general database maintenance.</li>
        <li>Frequent use of Entity Framework and LINQ, leveraging LINQKit.</li>
        <li>Maintaining and updating a REST API written in WCF. This allowed consumers to perform the core functions of the WinForms app.</li>
        <li>Worked on a web portal written in MVC 2 and jQuery.</li>
        <li>Worked on a prototype web app using MVC 4, Bootstrap, AngularJS and DevExpress MVC Client-Side Controls.</li>
      </ul></>)
  },
  {
    "title": "Technical Support",
    "employer": "Redwood Technologies",
    "link": "https://www.redwoodtech.com/",
    "from": "2011-08-01",
    "to": "2011-11-01",
    "descriptionHtml": (<>
      <p>I performed first and second line support at Redwood Technologies. I also worked on the internal ticketing system, written in PHP and MySQL.</p>
    </>)
  }
];

export function WorkHistory() {
  return (
    <section id="work-history">
      <div className="inner">
        <header className="major">
          <h2>Where I've Worked</h2>
        </header>
        {workHistory.map(h => {
          return (
            <div className="work-history-item">
              <div className="heading">
                <h3>{h.employer} - {h.title}</h3>
                <div className="subtitle">{dateFormat(h.from, 'mmmm yyyy')} - {h.to ? dateFormat(h.to, 'mmmm yyyy') : "present"}</div>
                {h.link && <div className="subtitle"><a href={h.link} target="_blank">{h.link}</a></div>}
              </div>
              <div className="description">
                {h.descriptionHtml}
              </div>
            </div>
          )
        })}
      </div>
    </section>
  );
}
