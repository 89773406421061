import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import goldivox from '../assets/images/goldivox.png';
import stunners from '../assets/images/stunners.png';
import phonsole from '../assets/images/phonsole.png';
import blog from '../assets/images/blog.png'
import github from '../assets/images/github-white.svg';
import intention from '../assets/images/intention.svg';
import jsonViewer from '../assets/images/json-viewer.png';
import lobsterWriter from '../assets/images/lobster-writer.png';
import only6 from '../assets/images/only-6.png';
import { WorkHistory } from '../components/WorkHistory';
import { Technologies } from '../components/Technologies';

const work = [
    {
        title: "Dave Martin Programming Blog",
        description: "My blog, where I write about programming",
        image: blog,
        link: "https://blog.davemartin.me"
    },
    {
        title: "Open Source",
        description: "I maintain and contribute towards open source libraries",
        image: github,
        link: "/work/open-source"
    }, 
    {
        title: "Intention",
        description: "A web app to plan and acheive goals",
        image: intention,
        link: "https://about.i.ntention.app"
    }, 
    {
        title: "Only 6",
        description: "An productivity app following the Ivy Lee method",
        image: only6,
        link: "https://play.google.com/store/apps/details?id=com.only6",
    },
    {
        title: "Phonsole",
        description: "A web app that turns your phone into a console display",
        image: phonsole,
        link: "https://davewm.github.io/phonsole-client"
    },
    {
        title: "Stunners",
        description: "A mobile app for booking stylists",
        image: stunners,
        link: "/work/stunners"
    },
    {
        title: "Goldivox",
        description: "A unique award winning interactive animation",
        image: goldivox,
        link: "/work/goldivox"
    },
    {
        title: "Lobster Writer",
        description: "A web app to write better essays",
        image: lobsterWriter,
        link: "https://about.lobster-writer.co.uk"
    }];

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Dave Martin Programming"
                    meta={[
                        { name: 'description', content: 'Dave Martin\'s Portfolio Site' },
                        { name: 'keywords', content: 'Portfolio CV Developer Dave Martin Clojure Functional' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                        {work.map(w => {
                            const link = w.link.startsWith('/') ? <Link to={w.link} className="link primary"></Link> : <a href={w.link} className="link primary" target="_blank"/>;
                            return (
                                <article style={{ backgroundImage: `url(${w.image})` }}>
                                    <header className="major">
                                        <h3>{w.title}</h3>
                                        <p>{w.description}</p>
                                    </header>
                                    {link}
                                </article>
                            )
                        })}
                    </section>
                    <Technologies />
                    <WorkHistory />
                </div>

            </Layout>
        )
    }
}

export default HomeIndex
