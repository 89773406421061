import React from 'react'
import logoImage from '../assets/images/logo-horizontal-white-small.png';
import headshotImage from '../assets/images/headshot-small.jpg';

const Banner = (props) => (
  <section id="banner" className="major">
    <div className="inner">
      <div className="content">
        <img className="banner-logo" src={logoImage}></img>
        <ul className="icons">
          <img className="avatar" src={headshotImage} />
          <li><a href="https://github.com/DaveWM" className="icon alt fa-github" target="_blank"><span className="label">GitHub</span></a></li>
          <li><a href="https://www.linkedin.com/in/davewm/" className="icon alt fa-linkedin" target="_blank"><span className="label">LinkedIn</span></a></li>
          <li><a href="mailto:mail@davemartin.me" className="icon alt fa-envelope" target="_blank"><span className="label">Email</span></a></li>
        </ul>
        <p>
          Hi, I'm Dave, an experienced full stack web developer based in Liverpool.
          I have over 10 years' experience across a variety of industries and tech stacks, focusing on Functional Programming.
        </p>
        <p>
          I can help you build solid, performant, and dependable full stack web applications.
          I do this by bringing the very best engineering practices and techniques to web development - Functional Programming, Unit Testing, and Event Sourcing.
          This allows me to deliver your application in record time, while not compromising on quality.
        </p>
        <p>
          Whether you need to build an application from scratch, or need some guidance with an existing application, contact me at <a href="mailto:mail@davemartin.me">mail@davemartin.me</a>.
        </p>
        <p>
          <blockquote>
            Dave is a top freelancer. Before Dave joined the team, the proposed target was expected to be completed in two months. Dave was able to complete it in 5 days! This had a large impact on our quarterly goals. His communication is on point (always available for a call), he understands the requirements well and writes quality code. We hope to work with Dave again in the future and we wish him the best of luck.
            <br /><br />
            Jeel Shah @ <a target="_blank" href="http://qitab.club">Qitab Club</a>
          </blockquote>
        </p>
        <ul className="actions">
          <li><a href="#one" className="button next scrolly">Portfolio</a></li>
          <li><a href="#work-history" className="button next scrolly">Work History</a></li>
          <li><a href="#contact" className="button next scrolly">Contact</a></li>
        </ul>
      </div>
    </div>
  </section>
)

export default Banner
