import { Link } from "gatsby";
import React from 'react';

import reactImg from '../assets/images/react.png';
import cljs from '../assets/images/cljs-small.png';
import js from '../assets/images/js.png';
import scss from '../assets/images/scss.png';
import elm from '../assets/images/elm.png';
import clj from '../assets/images/clj-small.png';
import kafka from '../assets/images/kafka.png';
import postgres from '../assets/images/postgres-small.png';
import node from '../assets/images/node.png';
import datomic from '../assets/images/datomic.png';
import git from '../assets/images/git.png';
import docker from '../assets/images/docker.png';
import aws from '../assets/images/aws.png';
import heroku from '../assets/images/heroku.png';
import angular from '../assets/images/angular.png';
import scala from '../assets/images/scala-small.png';

const techGroup = ({ name, images }) => {
    return (
        <div className="group">
            <header className="major">
                <h3>{name}</h3>
            </header>
            <div class="techs">
                {images.map(({ url, name }) => <div className="tech"><div className="image"><img src={url} title={name} /></div><span>{name}</span></div>)}
            </div>
        </div>
    );
}

export function Technologies() {
    const techs = [
        {
            name: "Front End",
            images: [
                { name: "ClojureScript", url: cljs },
                { name: "JavaScript", url: js },
                { name: "React", url: reactImg },
                { name: "Angular", url: angular },
                { name: "SCSS", url: scss },
                { name: "Elm", url: elm }
            ]
        },
        {
            name: "Back End",
            images: [
                {name: "Clojure", url: clj},
                {name: "Scala", url: scala},
                {name: "Kafka", url: kafka},
                {name: "PostgreSQL", url: postgres},
                {name: "NodeJS", url: node},
                {name: "Datomic", url: datomic}
            ]
        },
        {
            name: "Other",
            images: [
                {name: "Git", url: git},
                {name: "Docker", url: docker},
                {name: "AWS", url: aws},
                {name: "Heroku", url: heroku}
            ]
        }
    ];
    return (
        <section id="technologies">
            <div className="inner">
                <header className="major">
                    <h2>Technologies</h2>
                </header>
                {techs.map(techGroup)}
            </div>
        </section>
    );
}